import { gql } from 'apollo-angular';

export const BILLING_ACCOUNT_PERMISSIONS_FRAGMENT = gql`
  fragment BillingAccountPermissions on BillingAccountPermissions {
    userId
    canManageBundles
    canManageAccount
  }
`;

export const BILLING_ACCOUNT_PERMISSIONS_FULL_FRAGMENT = gql`
  fragment BillingAccountCurrentUserPermissions on BillingAccountPermissions {
    teamId
    billingAccountId
    canManageBundles
    canManageAccount
  }
`;

export const USER_BILLING_ACCOUNT_PERMISSIONS_QUERY = gql`
  query userBillingAccountPermissions($teamId: Int!, $billingAccountId: Int!) {
    userBillingAccountPermissions(teamId: $teamId, billingAccountId: $billingAccountId) {
      ...BillingAccountPermissions
    }
  }

  ${BILLING_ACCOUNT_PERMISSIONS_FRAGMENT}
`;

export const USER_BILLING_ACCOUNTS_PERMISSIONS_QUERY = gql`
  query userBillingAccountsPermissions {
    userBillingAccountsPermissions {
      ...BillingAccountCurrentUserPermissions,
    }
  }

  ${BILLING_ACCOUNT_PERMISSIONS_FULL_FRAGMENT}
`;

export const BILLING_ACCOUNT_USER_PERMISSIONS_QUERY = gql`
  query billingAccountUserPermissions($teamId: Int!, $billingAccountId: Int!) {
    billingAccountUserPermissions(teamId: $teamId, billingAccountId: $billingAccountId) {
      ...BillingAccountPermissions
    }
  }

  ${BILLING_ACCOUNT_PERMISSIONS_FRAGMENT}
`;

export const UPDATE_BILLING_ACCOUNT_PERMISSIONS_MUTATION = gql`
  mutation updateBillingAccountPermissions($teamId: Int!, $billingAccountId: Int!, $value: UpdateBillingAccountPermissionsInput!) {
    updateBillingAccountPermissions(teamId: $teamId, billingAccountId: $billingAccountId, value: $value) {
      ...BillingAccountPermissions
    }
  }

  ${BILLING_ACCOUNT_PERMISSIONS_FRAGMENT}
`;
