/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum BillingBundleStatus {
  ACTIVE = "ACTIVE",
  EMPTY = "EMPTY",
  EXPIRED = "EXPIRED",
  NEW = "NEW",
}

export enum BillingProductPackageType {
  PEOPLE_SEARCH_100_CREDITS = "PEOPLE_SEARCH_100_CREDITS",
  PEOPLE_SEARCH_1_CREDIT = "PEOPLE_SEARCH_1_CREDIT",
}

export enum ChangeRequestStatus {
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  SUBMITTED = "SUBMITTED",
}

export enum CreditType {
  BILLING_ACCOUNT = "BILLING_ACCOUNT",
  PERSONAL = "PERSONAL",
}

export enum KycStatus {
  DENIED = "DENIED",
  KYC_DISABLED = "KYC_DISABLED",
  LIMITED_ACCESS = "LIMITED_ACCESS",
  VETTED = "VETTED",
  VETTING_REQUIRED = "VETTING_REQUIRED",
}

export enum OtherEngagementDataType {
  ALIGNMENT_CONVERSATION = "ALIGNMENT_CONVERSATION",
  IN_PERSON = "IN_PERSON",
  LETTER = "LETTER",
  SOCIAL_MEDIA = "SOCIAL_MEDIA",
  TEXT = "TEXT",
}

export enum RelationshipSocialMediaType {
  FACEBOOK = "FACEBOOK",
  INSTAGRAM = "INSTAGRAM",
  LINKEDIN = "LINKEDIN",
  OTHER = "OTHER",
  TWITTER = "TWITTER",
}

export enum Roles {
  CASE_CREATOR = "CASE_CREATOR",
  EDITOR = "EDITOR",
  MANAGER = "MANAGER",
  MEMBER = "MEMBER",
  NONE = "NONE",
  VIEWER = "VIEWER",
}

export enum TaskStatusEnum {
  ERROR = "ERROR",
  FINISHED = "FINISHED",
  INPROGRESS = "INPROGRESS",
}

export enum TaskType {
  CASE = "CASE",
  PERSONAL = "PERSONAL",
}

export enum TemplateType {
  CALL = "CALL",
  EMAIL = "EMAIL",
  MESSAGE = "MESSAGE",
  VOICEMAIL = "VOICEMAIL",
}

export interface AccurintCredentialsInput {
  username: string;
  password: string;
}

export interface AccurintInput {
  uniqueId?: string | null;
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
  streetAddress1?: string | null;
  streetAddress2?: string | null;
  city?: string | null;
  state?: string | null;
  zipCode?: string | null;
  phoneNumber?: string | null;
  dob?: DateOfBirthInput | null;
}

export interface AddressInput {
  isVerified: boolean;
  verifiedById?: number | null;
  isHidden: boolean;
  country?: string | null;
  countryCode?: string | null;
  formatted?: string | null;
  latitude?: number | null;
  locality: string;
  longitude?: number | null;
  postalCode?: string | null;
  raw?: string | null;
  route: string;
  routeTwo?: string | null;
  state?: string | null;
  stateCode?: string | null;
  streetNumber: string;
  label?: string | null;
  dateOfInterest?: any | null;
  verifiedDate?: any | null;
  accurintImported?: boolean | null;
}

export interface AlternateNameInput {
  name: string;
  label: string;
  isVerified: boolean;
  verifiedById?: number | null;
  isHidden: boolean;
  verifiedDate?: any | null;
  accurintImported?: boolean | null;
}

export interface AuditLogInput {
  action: string;
  values: any;
  targetCaseId?: number | null;
  targetRelationshipId?: number | null;
  targetPersonId?: number | null;
  targetUserId?: number | null;
  targetEngagementId?: number | null;
  targetWorkspaceId?: number | null;
}

export interface BillingAccountPermissionsInput {
  userId: number;
  canManageBundles: boolean;
  canManageAccount: boolean;
}

export interface BooleanWrapper {
  val: boolean;
}

export interface ChangeUserAssignmentInput {
  oldUserId: number;
  newUserId: number;
  removeFromTeam?: boolean | null;
  cases?: boolean | null;
  engagements?: boolean | null;
  workpads?: boolean | null;
}

export interface CreateBillingAccount {
  name: string;
  emailAddress?: string | null;
  description: string;
  isDefault?: boolean | null;
}

export interface CreateCaseInput {
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
  suffix?: string | null;
  title?: string | null;
  race?: string | null;
  ethnicity?: string | null;
  tribalAffiliation?: string | null;
  identifiesAsLGBTQIAPlus?: string | null;
  category?: string | null;
  notes?: string | null;
  picture?: any | null;
  gender?: string | null;
  pronouns?: string | null;
  isSameSexPartner?: boolean | null;
  isIdentified?: boolean | null;
  dateIdentified?: any | null;
  isLocated?: boolean | null;
  dateLocated?: any | null;
  dateOfDeath?: any | null;
  dateOfDeathRaw?: string | null;
  isDeceased?: boolean | null;
  birthMonth?: number | null;
  birthYear?: number | null;
  dayOfBirth?: number | null;
  birthdayRaw?: string | null;
  addresses?: AddressInput[] | null;
  billingAccountId?: number | null;
  caseFileNumber?: string | null;
  fosterCare?: any | null;
  childStatusId?: number | null;
  caseStatusId: number;
  teamAttributes?: PersonTeamAttributeInput[] | null;
}

export interface CreateChangeEmailRequestInput {
  clientUrl: string;
  emailAddress: string;
  teamIds: number[];
}

export interface CreateEngagementCall {
  relationshipId?: number | null;
  isPublic: boolean;
  isDraft?: boolean | null;
  isTwoWayContact?: boolean | null;
  occurredAt?: any | null;
  note: string;
}

export interface CreateEngagementDocument {
  relationshipId?: number | null;
  isPublic: boolean;
  isDraft?: boolean | null;
  attachment: any;
  title: string;
  note: string;
  occurredAt?: any | null;
  imageToPdf?: boolean | null;
}

export interface CreateEngagementEmail {
  relationshipId?: number | null;
  isPublic: boolean;
  isDraft?: boolean | null;
  isTwoWayContact?: boolean | null;
  subject: string;
  occurredAt?: any | null;
  body: string;
}

export interface CreateEngagementNote {
  relationshipId?: number | null;
  isPublic: boolean;
  isDraft?: boolean | null;
  isTwoWayContact?: boolean | null;
  occurredAt?: any | null;
  note: string;
}

export interface CreateEngagementOther {
  relationshipId?: number | null;
  isPublic: boolean;
  isDraft?: boolean | null;
  isTwoWayContact?: boolean | null;
  occurredAt?: any | null;
  note: string;
  engagementType: OtherEngagementDataType;
}

export interface CreateNoPermissionUserInput {
  firstName: string;
  lastName: string;
  emailAddress: string;
  picture?: any | null;
}

export interface CreateRelationshipInput {
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
  suffix?: string | null;
  title?: string | null;
  category?: string | null;
  notes?: string | null;
  engagementNotes?: string | null;
  picture?: any | null;
  gender?: string | null;
  pronouns?: string | null;
  race?: string | null;
  ethnicity?: string | null;
  tribalAffiliation?: string | null;
  identifiesAsLGBTQIAPlus?: string | null;
  isSameSexPartner?: boolean | null;
  isIdentified?: boolean | null;
  dateIdentified?: any | null;
  isLocated?: boolean | null;
  dateLocated?: any | null;
  dateOfDeath?: any | null;
  dateOfDeathRaw?: string | null;
  isDeceased?: boolean | null;
  birthMonth?: number | null;
  birthYear?: number | null;
  dayOfBirth?: number | null;
  birthdayRaw?: string | null;
  socialMedia?: RelationshipSocialMediaInput[] | null;
  jobTitle?: string | null;
  employer?: string | null;
  salaryRangeId?: number | null;
  statusId?: number | null;
  isSeen?: boolean | null;
  isKnown?: boolean | null;
  isRelative?: boolean | null;
  isContacted?: boolean | null;
  firstContactedDate?: any | null;
  statusModifiedDate?: any | null;
  ppSearchImported?: boolean | null;
  accurintImported?: boolean | null;
  accurintUniqueId?: string | null;
  familySearchImported?: boolean | null;
  familySearchPersonId?: string | null;
  addresses?: AddressInput[] | null;
  emails?: EmailInput[] | null;
  telephones?: TelephoneInput[] | null;
  alternateNames?: AlternateNameInput[] | null;
  teamAttributes?: PersonTeamAttributeInput[] | null;
}

export interface CreateTaskInput {
  title: string;
  description?: string | null;
  isComplete?: boolean | null;
  dueDate?: DateWrapper | null;
  type: TaskType;
  caseId?: number | null;
  relationshipId?: number | null;
}

export interface CreateTeamAttributeInput {
  name: string;
  type: string;
  disabled: boolean;
  isChildBased: boolean;
  order: number;
  defaultValue: string;
  options?: string[] | null;
}

export interface CreateTeamInput {
  name: string;
  picture?: any | null;
  email?: string | null;
}

export interface CreateTemplateInput {
  name: string;
  subject?: string | null;
  content: string;
  isHidden?: boolean | null;
  isGlobal?: boolean | null;
  templateType?: TemplateType | null;
}

export interface CreateThemeInputAdmin {
  name: string;
  logo?: any | null;
  headerColor: string;
  headerLinkColor: string;
  headerActiveLinkColor: string;
  headerBorderColor: string;
  primaryColor: string;
  secondaryColor: string;
}

export interface CreateUserInput {
  firstName: string;
  lastName: string;
  emailAddress: string;
  picture?: any | null;
  role: Roles;
}

export interface CreateUserInputAdmin {
  firstName: string;
  lastName: string;
  emailAddress: string;
  picture?: any | null;
  teams: UserTeamInput[];
  isActive: boolean;
  isSiteAdmin: boolean;
  additionalRoles?: string | null;
  familyConnectionsLicensed?: boolean | null;
}

export interface DateOfBirthInput {
  year?: string | null;
  month?: string | null;
  day?: string | null;
}

export interface DateWrapper {
  val?: any | null;
}

export interface EmailInput {
  isVerified: boolean;
  verifiedById?: number | null;
  isHidden: boolean;
  emailAddress: string;
  label?: string | null;
  dateOfInterest?: any | null;
  verifiedDate?: any | null;
  accurintImported?: boolean | null;
}

export interface IntWrapper {
  val?: number | null;
}

export interface PersonTeamAttributeInput {
  teamAttributeId: number;
  value: string;
}

export interface RelationshipSocialMediaInput {
  url: string;
  type: RelationshipSocialMediaType;
}

export interface RelationshipStatusInput {
  name: string;
  colorBlind?: boolean | null;
  color: string;
  notes?: string | null;
  sortOrder?: number | null;
}

export interface RelationshipStatusInputAdmin {
  name: string;
  colorBlind?: boolean | null;
  color: string;
  notes?: string | null;
  sortOrder?: number | null;
  isTemplate?: boolean | null;
}

export interface SaveKycAnswersInput {
  workTitle?: string | null;
  agency?: string | null;
  contactPersonName?: string | null;
  contactPersonContactForm?: string | null;
  plan?: string | null;
  proofOfWorkImage?: UploadWrapper | null;
}

export interface StringWrapper {
  val?: string | null;
}

export interface TelephoneInput {
  isVerified: boolean;
  verifiedById?: number | null;
  isHidden: boolean;
  phoneNumber: string;
  label?: string | null;
  dateOfInterest?: any | null;
  verifiedDate?: any | null;
  accurintImported?: boolean | null;
}

export interface UpdateBillingAccount {
  name?: string | null;
  emailAddress?: string | null;
  description?: string | null;
  isDefault?: boolean | null;
}

export interface UpdateBillingAccountPermissionsInput {
  permissions: BillingAccountPermissionsInput[];
}

export interface UpdateBillingCreditsMetadata {
  bundleId: number;
  hasHiddenBundleIsEmptyWarning?: boolean | null;
  hasHiddenBundleIsExpiringSoonWarning?: boolean | null;
  hasHiddenBundleHasExpiredWarning?: boolean | null;
}

export interface UpdateCaseInput {
  firstName?: StringWrapper | null;
  middleName?: StringWrapper | null;
  lastName?: StringWrapper | null;
  suffix?: StringWrapper | null;
  title?: StringWrapper | null;
  race?: StringWrapper | null;
  ethnicity?: StringWrapper | null;
  tribalAffiliation?: StringWrapper | null;
  identifiesAsLGBTQIAPlus?: StringWrapper | null;
  category?: StringWrapper | null;
  notes?: StringWrapper | null;
  picture?: UploadWrapper | null;
  gender?: string | null;
  pronouns?: StringWrapper | null;
  isSameSexPartner?: boolean | null;
  isIdentified?: boolean | null;
  dateIdentified?: DateWrapper | null;
  isLocated?: boolean | null;
  dateLocated?: DateWrapper | null;
  dateOfDeath?: DateWrapper | null;
  dateOfDeathRaw?: StringWrapper | null;
  isDeceased?: boolean | null;
  birthMonth?: IntWrapper | null;
  birthYear?: IntWrapper | null;
  dayOfBirth?: IntWrapper | null;
  birthdayRaw?: StringWrapper | null;
  addresses?: AddressInput[] | null;
  billingAccountId?: IntWrapper | null;
  caseFileNumber?: StringWrapper | null;
  fosterCare?: DateWrapper | null;
  childStatusId?: IntWrapper | null;
  caseStatusId?: number | null;
  teamAttributes?: PersonTeamAttributeInput[] | null;
}

export interface UpdateEngagementCall {
  relationshipId?: IntWrapper | null;
  isPublic?: boolean | null;
  isDraft?: boolean | null;
  isTwoWayContact?: boolean | null;
  occurredAt?: DateWrapper | null;
  note?: string | null;
}

export interface UpdateEngagementDocument {
  relationshipId?: IntWrapper | null;
  isPublic?: boolean | null;
  isDraft?: boolean | null;
  attachment?: any | null;
  title?: string | null;
  note?: string | null;
  occurredAt?: DateWrapper | null;
  imageToPdf?: boolean | null;
}

export interface UpdateEngagementEmail {
  relationshipId?: IntWrapper | null;
  isPublic?: boolean | null;
  isDraft?: boolean | null;
  isTwoWayContact?: boolean | null;
  occurredAt?: DateWrapper | null;
  subject?: string | null;
  body?: string | null;
}

export interface UpdateEngagementNote {
  relationshipId?: IntWrapper | null;
  isPublic?: boolean | null;
  isDraft?: boolean | null;
  isTwoWayContact?: boolean | null;
  occurredAt?: DateWrapper | null;
  note?: string | null;
}

export interface UpdateEngagementOther {
  relationshipId?: IntWrapper | null;
  isPublic?: boolean | null;
  isDraft?: boolean | null;
  isTwoWayContact?: boolean | null;
  occurredAt?: DateWrapper | null;
  note?: string | null;
  engagementType: OtherEngagementDataType;
}

export interface UpdateProfileInput {
  firstName?: string | null;
  lastName?: string | null;
  picture?: UploadWrapper | null;
}

export interface UpdateRelationshipInput {
  firstName?: StringWrapper | null;
  middleName?: StringWrapper | null;
  lastName?: StringWrapper | null;
  suffix?: StringWrapper | null;
  title?: StringWrapper | null;
  category?: StringWrapper | null;
  notes?: StringWrapper | null;
  picture?: UploadWrapper | null;
  gender?: string | null;
  pronouns?: StringWrapper | null;
  race?: StringWrapper | null;
  ethnicity?: StringWrapper | null;
  tribalAffiliation?: StringWrapper | null;
  identifiesAsLGBTQIAPlus?: StringWrapper | null;
  isSameSexPartner?: boolean | null;
  isIdentified?: boolean | null;
  dateIdentified?: DateWrapper | null;
  isLocated?: boolean | null;
  dateLocated?: DateWrapper | null;
  dateOfDeath?: DateWrapper | null;
  dateOfDeathRaw?: StringWrapper | null;
  isDeceased?: boolean | null;
  birthMonth?: IntWrapper | null;
  birthYear?: IntWrapper | null;
  dayOfBirth?: IntWrapper | null;
  birthdayRaw?: StringWrapper | null;
  socialMedia?: RelationshipSocialMediaInput[] | null;
  jobTitle?: StringWrapper | null;
  employer?: StringWrapper | null;
  salaryRangeId?: IntWrapper | null;
  statusId?: IntWrapper | null;
  isSeen?: boolean | null;
  isKnown?: boolean | null;
  isRelative?: boolean | null;
  isContacted?: boolean | null;
  isTwoWayContacted?: boolean | null;
  firstContactedDate?: DateWrapper | null;
  statusModifiedDate?: DateWrapper | null;
  ppSearchImported?: boolean | null;
  ppSearchClicked?: boolean | null;
  accurintImported?: boolean | null;
  accurintUniqueId?: StringWrapper | null;
  familySearchImported?: boolean | null;
  familySearchPersonId?: StringWrapper | null;
  addresses?: AddressInput[] | null;
  emails?: EmailInput[] | null;
  telephones?: TelephoneInput[] | null;
  alternateNames?: AlternateNameInput[] | null;
  teamAttributes?: PersonTeamAttributeInput[] | null;
}

export interface UpdateTaskInput {
  title?: string | null;
  description?: string | null;
  isComplete?: boolean | null;
  dueDate?: DateWrapper | null;
  type?: TaskType | null;
  caseId?: number | null;
  relationshipId?: number | null;
}

export interface UpdateTeamAttributeInput {
  id: number;
  name?: string | null;
  type?: string | null;
  disabled?: boolean | null;
  isChildBased?: boolean | null;
  order?: number | null;
  defaultValue?: string | null;
  options?: string[] | null;
}

export interface UpdateTeamInput {
  name?: string | null;
  picture?: UploadWrapper | null;
  email?: StringWrapper | null;
}

export interface UpdateTemplateInput {
  name?: string | null;
  subject?: string | null;
  content?: string | null;
  isHidden?: boolean | null;
  isGlobal?: boolean | null;
  templateType?: TemplateType | null;
}

export interface UpdateThemeInputAdmin {
  id: number;
  name?: string | null;
  logo?: any | null;
  headerColor?: string | null;
  headerLinkColor?: string | null;
  headerActiveLinkColor?: string | null;
  headerBorderColor?: string | null;
  primaryColor?: string | null;
  secondaryColor?: string | null;
}

export interface UpdateUserInput {
  firstName?: string | null;
  lastName?: string | null;
  picture?: UploadWrapper | null;
  role?: Roles | null;
}

export interface UpdateUserInputAdmin {
  firstName?: string | null;
  lastName?: string | null;
  picture?: UploadWrapper | null;
  teams?: UserTeamInput[] | null;
  kycStatus?: KycStatus | null;
  isActive?: BooleanWrapper | null;
  isSiteAdmin?: BooleanWrapper | null;
  additionalRoles?: StringWrapper | null;
  familyConnectionsLicensed?: boolean | null;
}

export interface UpdateUserKycStatusInputAdmin {
  kycStatus: KycStatus;
  message: string;
}

export interface UpdateUserRequestKycAnswersEditingInputAdmin {
  approved: boolean;
}

export interface UploadWrapper {
  val?: any | null;
}

export interface UserTeamInput {
  teamId: number;
  role: Roles;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
