import { Injectable } from "@angular/core"
import { Observable, filter, take, zip } from "rxjs"
import { BillingBundleStatus, CreditType } from "../../../generated/globalTypes";
import { BillingCreditsBundle } from "../../../generated/BillingCreditsBundle";
import * as moment from "moment";
import { CreditBundleService } from "./credit-bundle.service";
import { BillingService } from "./billing.service";
import { NotificationService } from "./notification.service";
import { UserService } from "../../auth0/services/user.service";
import { BillingCreditsBundleMetadata } from "../../../generated/BillingCreditsBundleMetadata";
import { BundleExpirationNotificationComponent } from "../components/bundle-expiration-notification/bundle-expiration-notification.component";
import { BillingAccount } from "../../../generated/BillingAccount";
import { BillingPermissionsService } from "./billing-permissions.service";
import { BillingAccountCurrentUserPermissions } from "../../../generated/BillingAccountCurrentUserPermissions";
import { UserFull } from "../../../generated/UserFull";

export enum BillingBundleNotificationType {
  EMPTY_BUNDLE = 'EMPTY_BUNDLE',
  BUNDLE_EXPIRING_IN_A_WEEK = 'BUNDLE_EXPIRING_IN_A_WEEK',
  BUNDLE_EXPIRED = 'BUNDLE_EXPIRED',
  NO_NOTIFICATION = 'NO_NOTIFICATION'
}

export interface BillingBundleNotificationData {
  teamId?: number;
  accountName: string;
  accountType: CreditType;
  amountOfNotifications: number;
  notificationType: BillingBundleNotificationType;
}

interface BillingAccountInfo {
  teamId?: number;
  type: CreditType;
  accountName: string;
}

interface AccountBundleNotificationInfo {
  emptyBundleNotificationsAmount: number;
  expiredBundleNotificationsAmount: number;
  willExpireInAweekNotificationAmount: number;
}

@Injectable({
  providedIn: 'root'
})
export class BundleNotifactionsService {
  private readonly PERSONAL_BILLING_ACCOUNT_ID = -1;

  constructor(
    private userService: UserService,
    private notificationService: NotificationService,
    private creditsBundleService: CreditBundleService,
    private billingAccountService: BillingService,
    private billingPermissionsService: BillingPermissionsService,
  ) {
    this.initializeNotificationsProcessing();
  }

  private initializeNotificationsProcessing(): void {
    this.userService.currentUser$
      .pipe(filter(user => user != null), take(1))
      .subscribe((user) => {
        this.creditsBundleService.getBillingCreditBundlesOfUser()
          .subscribe((bundles) => this.processBillingAccountsNotifications(user, bundles));
      })
  }

  private processBillingAccountsNotifications(user: UserFull, bundles: BillingCreditsBundle[]): void {
    const billingAccountsObservable = this.billingAccountService.getBillingAccounts().pipe(take(1));
    const userBillingAccountPermissionsObservable = this.billingPermissionsService.getUserBillingAccountsPermissions().pipe(take(1));
    zip([billingAccountsObservable, userBillingAccountPermissionsObservable])
      .subscribe(([billingAccounts, userBillingAccountPermissions]) => {
        const userBillingAccount = {
          id: this.PERSONAL_BILLING_ACCOUNT_ID,
          name: "Personal Account"
        } as BillingAccount;

        const billingAccountsWithUserAccount = [userBillingAccount, ...billingAccounts];
        billingAccountsWithUserAccount.forEach(billingAccount =>
          this.processBillingAccountNotifications(billingAccount, user, bundles, userBillingAccountPermissions)
        );
      });
  }

  private processBillingAccountNotifications(
    billingAccount: BillingAccount,
    user: UserFull,
    bundles: BillingCreditsBundle[],
    userBillingAccountPermissions: BillingAccountCurrentUserPermissions[]
  ): void {
    const isUserOwned = billingAccount.id === this.PERSONAL_BILLING_ACCOUNT_ID;
    const ownerId = isUserOwned ? user!.id : billingAccount.id;

    const canManageBundlesOfAccount = isUserOwned ? true : this.canUserManageBundlesOfAccount(billingAccount.teamId, billingAccount.id, userBillingAccountPermissions);

    const bundlesNotificationInfo = this.getNotificationInfoForBundlesOfAccount(ownerId, bundles, isUserOwned, canManageBundlesOfAccount);
    const hasAvailableBundle = this.accountHasAvailableBundle(ownerId, bundles, isUserOwned);

    const account = {
      teamId: billingAccount.teamId,
      accountName: billingAccount.name, 
      type: isUserOwned ? CreditType.PERSONAL : CreditType.BILLING_ACCOUNT
    };

    this.showNotificationsForAccount(account, hasAvailableBundle, bundlesNotificationInfo);
  }

  private canUserManageBundlesOfAccount(
    teamId: number, 
    billingAccountId: number,
    permissions: BillingAccountCurrentUserPermissions[]
  ): boolean {
    const isTeamManager = this.userService.isManager(teamId);
    if (isTeamManager) {
      return true;
    }
    
    const accountPermissions = permissions.find(permission => permission.billingAccountId === billingAccountId);
    if (!accountPermissions) {
      return false;
    }

    return accountPermissions.canManageAccount || accountPermissions.canManageBundles;
  }

  private accountHasAvailableBundle(ownerId: number, bundles: BillingCreditsBundle[], isUserOwned: boolean): boolean {
    return bundles.filter((bundle) => this.bundleBelongsToAccount(ownerId, bundle, isUserOwned)).some(bundle => this.isBundleAvailable(bundle))
  }

  private showNotificationsForAccount(account: BillingAccountInfo, hasAvailableBundle: boolean, notificationInfo: AccountBundleNotificationInfo) {
    if (!hasAvailableBundle) {
      this.showBundleByTypeIfPossible(account, notificationInfo.emptyBundleNotificationsAmount, BillingBundleNotificationType.EMPTY_BUNDLE);
    }

    this.showBundleByTypeIfPossible(account, notificationInfo.expiredBundleNotificationsAmount, BillingBundleNotificationType.BUNDLE_EXPIRED);
    this.showBundleByTypeIfPossible(account, notificationInfo.willExpireInAweekNotificationAmount, BillingBundleNotificationType.BUNDLE_EXPIRING_IN_A_WEEK);
  }

  private getNotificationInfoForBundlesOfAccount(
    ownerId: number,
    bundles: BillingCreditsBundle[],
    isUserOwned: boolean,
    canUserManageBundles: boolean
  ): AccountBundleNotificationInfo {
    let emptyBundleNotificationsAmount = 0;
    let expiredBundleNotificationsAmount = 0;
    let willExpireInAweekNotificationAmount = 0;

    bundles.filter((bundle) => this.bundleBelongsToAccount(ownerId, bundle, isUserOwned)).forEach(bundle => {
      const notificationType = this.getNotificationType(bundle, canUserManageBundles);
      emptyBundleNotificationsAmount += notificationType === BillingBundleNotificationType.EMPTY_BUNDLE ? 1 : 0;
      expiredBundleNotificationsAmount += notificationType === BillingBundleNotificationType.BUNDLE_EXPIRED ? 1 : 0;
      willExpireInAweekNotificationAmount += notificationType === BillingBundleNotificationType.BUNDLE_EXPIRING_IN_A_WEEK ? 1 : 0;

      if (notificationType !== BillingBundleNotificationType.NO_NOTIFICATION) {
        this.hideBundleNotification(bundle.id, notificationType, canUserManageBundles);
      }
    });

    return {
      emptyBundleNotificationsAmount,
      expiredBundleNotificationsAmount,
      willExpireInAweekNotificationAmount
    };
  }

  private bundleBelongsToAccount(ownerId: number, bundle: BillingCreditsBundle, isUserOwned: boolean) {
    return isUserOwned ? bundle.userId === ownerId : bundle.billingAccountId === ownerId;
  }

  private showBundleByTypeIfPossible(account: BillingAccountInfo, amountOfNotifications: number, notificationType: BillingBundleNotificationType) {
    if (amountOfNotifications > 0) {
      this.notificationService.showNewMessage({
        toastComponent: BundleExpirationNotificationComponent,
        toastClass: "custom-mat-card",
        positionClass: "notification-top-right",
        newestOnTop: true,
        disableTimeOut: true,
        payload: {
          teamId: account.teamId,
          accountName: account.accountName,
          notificationType,
          accountType: account.type,
          amountOfNotifications
        },
      });
    }
  }

  private updateBundleMetadata(bundleId: number, notificationType: BillingBundleNotificationType): Observable<BillingCreditsBundle> {
    const metadata = {
      hasHiddenBundleIsEmptyWarning: notificationType == BillingBundleNotificationType.EMPTY_BUNDLE ? true : undefined,
      hasHiddenBundleIsExpiringSoonWarning: notificationType == BillingBundleNotificationType.BUNDLE_EXPIRING_IN_A_WEEK ? true : undefined,
      hasHiddenBundleHasExpiredWarning: notificationType == BillingBundleNotificationType.BUNDLE_EXPIRED ? true : undefined
    } as BillingCreditsBundleMetadata;
    return this.billingAccountService.updateBundleMetadata(bundleId, metadata);
  }

  private isBundleAvailable(bundle: BillingCreditsBundle) {
    return bundle.status === BillingBundleStatus.ACTIVE || bundle.status === BillingBundleStatus.NEW;
  }

  private getNotificationType(bundle: BillingCreditsBundle, canUserManageBundle: boolean): BillingBundleNotificationType {
    if (bundle.status === BillingBundleStatus.EMPTY && !this.hasUserHiddenEmptyBundleNotification(bundle, canUserManageBundle)) {
      return BillingBundleNotificationType.EMPTY_BUNDLE;
    }

    if (bundle.status === BillingBundleStatus.EXPIRED && !this.hasUserHiddenBundleExpiredNotification(bundle, canUserManageBundle)) {
      return BillingBundleNotificationType.BUNDLE_EXPIRED;
    }

    if (this.bundleExpiresInAWeek(bundle, canUserManageBundle)) {
      return BillingBundleNotificationType.BUNDLE_EXPIRING_IN_A_WEEK;
    }

    return BillingBundleNotificationType.NO_NOTIFICATION;
  }

  private bundleExpiresInAWeek(bundle: BillingCreditsBundle, canUserManageBundle: boolean): boolean {
    const bundleIsUsable = (bundle.status != BillingBundleStatus.EXPIRED && bundle.status != BillingBundleStatus.EMPTY);
    if (bundleIsUsable && !this.hasUserHiddenBundleExpiresInOneWeekNotification(bundle, canUserManageBundle)) {
      return moment(bundle.expirationDate).subtract(1, "week").isBefore(new Date());
    }

    return false;
  }

  private hideBundleNotification(bundleId: number, notificationType: BillingBundleNotificationType, canUserManageBundles: boolean): void {
    if (canUserManageBundles) {
      this.updateBundleMetadata(bundleId, notificationType).pipe(take(1)).subscribe();
    } else {
      this.hideBundleNotificationLocally(bundleId, notificationType);
    }
  }

  private hasUserHiddenEmptyBundleNotification(bundle: BillingCreditsBundle, canUserManageBundle: boolean) {
    if (canUserManageBundle) {
      return bundle.metadata?.hasHiddenBundleIsEmptyWarning;
    }

    return bundle.metadata?.hasHiddenBundleIsEmptyWarning || this.hasHiddenBundleNotificationLocally(bundle.id, BillingBundleNotificationType.EMPTY_BUNDLE);
  }

  private hasUserHiddenBundleExpiredNotification(bundle: BillingCreditsBundle, canUserManageBundle: boolean) {
    if (canUserManageBundle) {
      return bundle.metadata?.hasHiddenBundleHasExpiredWarning;
    }

    return bundle.metadata?.hasHiddenBundleHasExpiredWarning || this.hasHiddenBundleNotificationLocally(bundle.id, BillingBundleNotificationType.BUNDLE_EXPIRED);
  }

  private hasUserHiddenBundleExpiresInOneWeekNotification(bundle: BillingCreditsBundle, canUserManageBundle: boolean) {
    if (canUserManageBundle) {
      return bundle.metadata?.hasHiddenBundleIsExpiringSoonWarning;
    }

    return bundle.metadata?.hasHiddenBundleIsExpiringSoonWarning || this.hasHiddenBundleNotificationLocally(bundle.id, BillingBundleNotificationType.BUNDLE_EXPIRING_IN_A_WEEK);
  }

  private hideBundleNotificationLocally(bundleId: number, notification: BillingBundleNotificationType): void {
    const key = this.getBundleNotificationHidingLocallyKey(bundleId, notification);
    localStorage.setItem(key, 'true');
  }

  private hasHiddenBundleNotificationLocally(bundleId: number, notification: BillingBundleNotificationType): boolean {
    const key = this.getBundleNotificationHidingLocallyKey(bundleId, notification);
    return localStorage.getItem(key) === 'true';
  }

  private getBundleNotificationHidingLocallyKey(bundleId: number, notification: BillingBundleNotificationType): string {
    return `NOTIFICATION_HIDDEN__${notification}__${bundleId}`;
  }
}
