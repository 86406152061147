import { Component, ChangeDetectionStrategy } from '@angular/core';
import { UserService } from "../../auth0/services/user.service";

@Component({
  selector: 'cok-no-access-page',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <section class="lyt-form not-found">
      <div class="inner">
        <a [routerLink]="['/']" class="logo"
        ><img src="./assets/images/logo-connect.png" alt="Connect Our Kids"/><span>Connect Our Kids</span></a
        >
        <div class="text-lable">Sorry, but you don't currently have access to this app. Please contact support for assistance. </div>

        <div class="col-sm-12 small-desc">
          If you want to log out from Family Connections, please click <a (click)="logout()"><b>here</b></a>.
        </div>
      </div>
    </section>
  `,
  styleUrls:['no-access-page.scss']
})
export class NoAccessPageComponent {

  constructor(private userService: UserService) {
  }

  public logout(): void {
    this.userService.logout();
  }

}
