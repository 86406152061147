import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from "@angular/router";
import { UserService } from "./auth0/services/user.service";
import { MixpanelService } from "./shared/services/mixpanel.service";

export const NO_ACCESS_PATH = 'no-access';

@Injectable()
export class LicenseGuard implements CanActivate {
  constructor(private userService: UserService,  public router: Router, private mixPanelService: MixpanelService) {
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {

    try {
      const user = await this.userService.ensureUser();

      if (!user.familyConnectionsLicensed) {
        this.mixPanelService.trackAction("user-without-license-logged-in");
        this.router.navigate(["no-access"]);
      }
    } catch (err) {
      console.error(err);
      return false;
    }

    return true;
  }

}
