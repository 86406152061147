<cok-layout>
  <nav class="navbar navbar-expand-md navbar-dark header-bg navbar-border py-2 px-3" *ngIf="loadedTheme && (loggedIn$ | async) && currentUser$ | async; let currentUser">
    <a *ngIf="!theme?.logo" [routerLink]="isKycDisabled ? null : ['/']" class="navbar-brand me-4">
      <img class="avatar" src="./assets/images/logo-white.png" alt="Family Connections" />
      Family Connections
    </a>
    <a *ngIf="theme?.logo" [routerLink]="isKycDisabled ? null : ['/']" class="navbar-brand">
      <img class="seneca-img" [src]="theme?.logo" alt="Family Connections" />
    </a>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarNav"
      aria-controls="navbarNav"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div *ngIf="currentUser.familyConnectionsLicensed" class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav me-auto">
        <li class="nav-item me-4" [routerLinkActive]="['active']" *ngIf="currentUser?.userTeams.length > 0">
          <a class="nav-link" *ngIf="!isKycDisabled" [routerLink]="['/dashboard']">Dashboard</a>
        </li>
        <li class="nav-item me-4" [routerLinkActive]="['active']" *ngIf="currentUser?.userTeams.length > 0">
          <a class="nav-link" *ngIf="!isKycDisabled" [routerLink]="['/cases/my']">Cases</a>
        </li>
        <li class="nav-item me-4" [routerLinkActive]="['active']" *ngIf="checkTeamPermissions()">
          <a class="nav-link" *ngIf="!isKycDisabled" [routerLink]="['/cases/team']">Team</a>
        </li>
        <li class="nav-item me-4" [routerLinkActive]="['active']" *ngIf="currentUser?.userTeams.length > 0">
          <a class="nav-link" *ngIf="!isKycDisabled" [routerLink]="['/reports']">Reports</a>
        </li>
        <!-- <li class="nav-item me-4" [routerLinkActive]="['active']" *ngIf="currentUser?.userTeams.length > 0">
          <a class="nav-link" *ngIf="!isKycDisabled" [routerLink]="['/billing']">Billing</a>
        </li> -->
        <li class="nav-item me-4" [routerLinkActive]="['active']" *ngIf="currentUser?.isSiteAdmin">
          <a class="nav-link" *ngIf="!isKycDisabled" [routerLink]="['/admin']">Site Admin</a>
        </li>
      </ul>

      <ul class="navbar-nav">
        <li class="nav-item me-3" [routerLinkActive]="['active']" *ngIf="currentUser?.userTeams.length > 0">
          <a class="nav-link" (click)="onAlertIconClick()" [routerLink]="['/tasks']"><mat-icon class="task-icon">notifications_none</mat-icon>
            <span class="red-circle" *ngIf="hasUnfinishedTasks"></span>
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link me-4"
            id="help"
            href="https://help.connectourkids.org/en/collections/2075404-family-connections"
            target="_blank"
            placement="bottom"
          >
            Help
          </a>
        </li>

        <li class="nav-item dropdown">
          <div
            class="nav-link dropdown-toggle"
            href="#"
            id="navbarDropdownMenuLink"
            role="button"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <div class="avatar avatar-border-white" [style.background-image]="(currentUser$ | async)?.picture | avatar | asUrl"></div>
          </div>

          <div class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdownMenuLink">
            <ng-container *ngIf="!isKycDisabled">
              <a [routerLink]="['/user/profile']" class="dropdown-item">My Profile</a>
              <a [routerLink]="['/settings/team']" class="dropdown-item">Team Settings</a>
              <hr />
            </ng-container>

            <a *ngIf="currentUser?.userTeams.length > 0" class="dropdown-item" [routerLink]="['/billing']">Billing</a>

            <hr />

            <a class="dropdown-item" (click)="showMobileAppModal($event)">Get Mobile App</a>

            <a
              class="dropdown-item"
              target="_blank"
              href="https://chrome.google.com/webstore/detail/connect-our-kids-demo/oijknkkppdgobaidnmkfkcbogcfkccom/related?hl=en&authuser=0"
              >Get Plugin</a
            >

            <hr />

            <a class="dropdown-item" href="https://www.connectourkids.org/terms" target="_blank">Terms & Conditions</a>
            <a class="dropdown-item" href="https://www.connectourkids.org/privacy" target="_blank">Privacy Policy</a>

            <hr />

            <a class="dropdown-item" (click)="showPoweredByModal($event)">Our Partners</a>

            <hr />

            <a (click)="logout()" class="dropdown-item">Logout</a>
          </div>
        </li>
      </ul>
    </div>
  </nav>

  <router-outlet></router-outlet>

  <!-- This component shows a screen when screen size is small. Ignore it for site admin section -->
  <cok-prevent-screen *ngIf="!router.url.startsWith('/admin')"></cok-prevent-screen>
</cok-layout>
