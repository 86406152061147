import { KYC_ANSWERS_DETAIL_FRAGMENT } from '../../../app/shared/fragments/kyc-answers';
import { gql } from 'apollo-angular';

export const USER_DETAIL_FRAGMENT = gql`
  fragment UserDetail on User {
    id
    firstName
    lastName
    email
    mfaEnabled
    isActive
    lastLogin
    creditsCount
    kycStatus
    isSiteAdmin
    additionalRoles
    updatedAt
    familyConnectionsLicensed
    userTeams {
      id
      role
      team {
        id
        name
      }
    }
  }
`;

export const USERS_DETAIL_QUERY = gql`
  query usersDetail {
    usersAdmin {
      ...UserDetail
    }
  }

  ${USER_DETAIL_FRAGMENT}
`;

export const USER_DETAIL_QUERY = gql`
  query userDetails($caseId: Int!) {
    userAdmin(id: $caseId) {
      ...UserDetail
    }
  }

  ${USER_DETAIL_FRAGMENT}
`;

export const USER_KYC_ANSWERS_QUERY = gql`
  query userKycAnswersAdmin($userId: Int!) {
    userKycAnswersAdmin(userId: $userId) {
      ...KycAnswersDetails
    }
  }

  ${KYC_ANSWERS_DETAIL_FRAGMENT}
`;

export const DELETE_USERS_LETSMFA_MUTATION = gql`
  mutation deleteUserLetsMFA($userId: Int!) {
    deleteUserLetsMFA(userId: $userId) {
      ...UserDetail
    }
  }

  ${USER_DETAIL_FRAGMENT}
`;
