import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { Observable, ReplaySubject, map } from "rxjs";
import { BILLING_ACCOUNT_USER_PERMISSIONS_QUERY, UPDATE_BILLING_ACCOUNT_PERMISSIONS_MUTATION, USER_BILLING_ACCOUNT_PERMISSIONS_QUERY, USER_BILLING_ACCOUNTS_PERMISSIONS_QUERY } from "../fragments/billing-account-permissions";
import { updateBillingAccountPermissions, updateBillingAccountPermissionsVariables } from "../../../generated/updateBillingAccountPermissions";
import { billingAccountUserPermissions, billingAccountUserPermissionsVariables } from "../../../generated/billingAccountUserPermissions";
import { UpdateBillingAccountPermissionsInput } from "../../../generated/globalTypes";
import { BillingAccountPermissions } from "../../../generated/BillingAccountPermissions";
import { BillingAccount } from "../../../generated/BillingAccount";
import { userBillingAccountPermissions, userBillingAccountPermissionsVariables } from "../../../generated/userBillingAccountPermissions";
import { userBillingAccountsPermissions } from "../../../generated/userBillingAccountsPermissions";
import { BillingAccountCurrentUserPermissions } from "../../../generated/BillingAccountCurrentUserPermissions";

@Injectable({ providedIn: 'root' })
export class BillingPermissionsService {

  private readonly billingAccountsPermissions = new ReplaySubject<BillingAccountCurrentUserPermissions[]>(1);
  private loadedBillingAccountsPermissions = false;

  public constructor(
    private apollo: Apollo,
  ) {
  }

  public updateBillingAccountPermissions(account: BillingAccount, value: UpdateBillingAccountPermissionsInput): Observable<BillingAccountPermissions[]> {
    return this.apollo.mutate<updateBillingAccountPermissions, updateBillingAccountPermissionsVariables>({
      mutation: UPDATE_BILLING_ACCOUNT_PERMISSIONS_MUTATION,
      variables: { teamId: account.teamId, billingAccountId: account.id, value },
      fetchPolicy: 'network-only'
    })
      .pipe(
        map((response) => [...response.data!.updateBillingAccountPermissions])
      );
  }

  public loadBillingAccountUserPermissions(account: BillingAccount): Observable<BillingAccountPermissions[]> {
    return this.apollo.query<billingAccountUserPermissions, billingAccountUserPermissionsVariables>({
      query: BILLING_ACCOUNT_USER_PERMISSIONS_QUERY,
      variables: { teamId: account.teamId, billingAccountId: account.id },
      fetchPolicy: 'network-only'
    })
      .pipe(
        map((response) => [...response.data!.billingAccountUserPermissions]),
      );
  }

  public loadUserBillingAccountPermissions(account: BillingAccount): Observable<BillingAccountPermissions> {
    return this.apollo.query<userBillingAccountPermissions, userBillingAccountPermissionsVariables>({
      query: USER_BILLING_ACCOUNT_PERMISSIONS_QUERY,
      variables: { teamId: account.teamId, billingAccountId: account.id },
      fetchPolicy: 'network-only'
    })
      .pipe(
        map((response) => response.data!.userBillingAccountPermissions),
      );
  }

  public getUserBillingAccountsPermissions(): Observable<BillingAccountCurrentUserPermissions[]> {
    if (!this.loadedBillingAccountsPermissions) {
      this.loadedBillingAccountsPermissions = true;

      this.apollo.query<userBillingAccountsPermissions>({
        query: USER_BILLING_ACCOUNTS_PERMISSIONS_QUERY,
        fetchPolicy: 'network-only'
      })
        .subscribe((response) => {
          const permissions = response.data!.userBillingAccountsPermissions;
          this.billingAccountsPermissions.next(permissions);
        });
    }

    return this.billingAccountsPermissions;
  }
}
