import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';

import { AuthorizeComponent } from './containers/authorize.component';
import { NoTeamComponent } from './containers/no-team/no-team.component';
import { AnonymousGuard } from './guards/anonymous.guard';
import { TeamGuard } from './guards/team.guard';
import { TeamManagerGuard } from './guards/team-manager.guard';
import { ErrorGuard } from './guards/error.guard';
import { UserService } from './services/user.service';
import { AuthService as AuthService } from './services/auth.service';
import { SiteAdminGuard } from './guards/siteAdmin.guard';
import { AuthGuard } from './guards/auth.guard';
import { ConfigService } from './services/config.service';
import { LicenseGuard } from "../license.guard";

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: 'callback',
        component: AuthorizeComponent,
      },
      {
        path: 'no-team',
        component: NoTeamComponent,
        canActivate: [LicenseGuard, ErrorGuard],
      },
    ]),
    ReactiveFormsModule,
  ],
  declarations: [AuthorizeComponent, NoTeamComponent],
})
export class Auth0Module {
  static forRoot(): ModuleWithProviders<Auth0Module> {
    return {
      ngModule: Auth0Module,
      providers: [AuthService, UserService, ConfigService, AuthGuard, TeamGuard, TeamManagerGuard, SiteAdminGuard, AnonymousGuard, ErrorGuard, LicenseGuard],
    };
  }
}
