import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgClickOutsideModule } from 'ng-click-outside2';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { AppComponent } from './containers/app';
import { NotFoundPageComponent } from './containers/not-found-page';
import { LayoutComponent } from './components/layout';
import { PreventScreenComponent } from './components/prevent-screen/prevent-screen.component';
import { SponsorsModalComponent } from './components/sponsors-modal/sponsors-modal.component';
import { UnsupportedBrowserComponent } from './components/unsupported-browser/unsupported-browser.component';
import { SharedModule } from '../shared/shared.module';
import { MaterialModule } from '../material.module';
import { NoAccessPageComponent } from "./containers/no-access-page";

export const COMPONENTS = [
  AppComponent,
  NotFoundPageComponent,
  NoAccessPageComponent,
  LayoutComponent,
  PreventScreenComponent,
  SponsorsModalComponent,
  UnsupportedBrowserComponent,
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        ModalModule.forRoot(),
        TooltipModule.forRoot(),
        SharedModule,
        NgClickOutsideModule,
        PerfectScrollbarModule,
        FontAwesomeModule,
        MaterialModule
    ],
    declarations: COMPONENTS,
    exports: [TooltipModule, ...COMPONENTS]
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [BsModalService],
    };
  }
}
