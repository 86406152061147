import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { Observable, ReplaySubject, catchError, map, switchMap, take, tap } from "rxjs";
import { BillingAccount } from "../../../generated/BillingAccount";
import { BILLING_ACCOUNTS_QUERY, CREATE_BILLING_ACCOUNT_CHECKOUT_SESSION_MUTATION, CREATE_BILLING_ACCOUNT_CUSTOMER_PORTAL_SESSION_MUTATION, CREATE_BILLING_ACCOUNT_MUTATION, CREATE_PERSONAL_BILLING_ACCOUNT_CHECKOUT_SESSION_MUTATION, CREATE_USER_CUSTOMER_PORTAL_SESSION_MUTATION, DELETE_BILLING_ACCOUNT_MUTATION, GET_BILLING_CREDITS_USAGES_QUERY, GET_BILLING_CREDITS_USAGE_STATISTICS_QUERY, GET_BILLING_CREDITS_USAGE_STATISTICS_REPORT_QUERY, GET_USER_BILLING_CREDITS_USAGES_FOR_BILLING_ACCOUNT_QUERY, GET_USER_BILLING_CREDITS_USAGES_QUERY, UPDATE_BILLING_ACCOUNT_MUTATION } from "../fragments/billing-accounts";
import { billingAccounts, billingAccountsVariables } from "../../../generated/billingAccounts";
import { BillingProductPackageType, CreateBillingAccount, UpdateBillingAccount } from "../../../generated/globalTypes";
import { createBillingAccount, createBillingAccountVariables } from "../../../generated/createBillingAccount";
import { createUserCustomerPortalSession } from "../../../generated/createUserCustomerPortalSession";
import { createBillingAccountCustomerPortalSession, createBillingAccountCustomerPortalSessionVariables } from "../../../generated/createBillingAccountCustomerPortalSession";
import { BillingAccountCustomerPortalSession } from "../../../generated/BillingAccountCustomerPortalSession";
import { deleteBillingAccount, deleteBillingAccountVariables } from "../../../generated/deleteBillingAccount";
import { updateBillingAccount, updateBillingAccountVariables } from "../../../generated/updateBillingAccount";
import { BillingAccountCheckoutSession } from "../../../generated/BillingAccountCheckoutSession";
import { createBillingAccountCheckoutSession, createBillingAccountCheckoutSessionVariables } from "../../../generated/createBillingAccountCheckoutSession";
import { environment } from "@env/environment";
import { createPersonalBillingCheckoutSession, createPersonalBillingCheckoutSessionVariables } from "../../../generated/createPersonalBillingCheckoutSession";
import { billingCreditsUsageStatistics, billingCreditsUsageStatisticsVariables } from "../../../generated/billingCreditsUsageStatistics";
import { BillingCreditUsageUserStatistics } from "../../../generated/BillingCreditUsageUserStatistics";
import { billingCreditsUsageStatisticsReport, billingCreditsUsageStatisticsReportVariables } from "../../../generated/billingCreditsUsageStatisticsReport";
import { updateBundleMetadata, updateBundleMetadataVariables } from "../../../generated/updateBundleMetadata";
import { BillingCreditUsageUserStatisticsReport } from "../../../generated/BillingCreditUsageUserStatisticsReport";
import { billingCreditsUsages, billingCreditsUsagesVariables } from "../../../generated/billingCreditsUsages";
import { BillingCreditUsage } from "../../../generated/BillingCreditUsage";
import { UPDATE_BUNDLE_METADATA } from "../fragments/billing-credits-bundle";
import { BillingCreditsBundle } from "../../../generated/BillingCreditsBundle";
import { BillingCreditsBundleMetadata } from "../../../generated/BillingCreditsBundleMetadata";
import { CreditBundleService } from "./credit-bundle.service";
import { billingCreditsUsagesForUser, billingCreditsUsagesForUserVariables } from "../../../generated/billingCreditsUsagesForUser";
import { billingCreditsUsagesForBillingAccount, billingCreditsUsagesForBillingAccountVariables } from "../../../generated/billingCreditsUsagesForBillingAccount";

@Injectable({ providedIn: 'root' })
export class BillingService {

  private billingAccounts = new ReplaySubject<BillingAccount[]>(1);
  private hasLoadedAccountsInitially = false;

  public constructor(
    private apollo: Apollo,
    private creditsBundleService: CreditBundleService,
  ) {
  }

  public getCurrentBillingAccounts(): Observable<BillingAccount[]> {
    return this.getBillingAccounts(false).pipe(take(1));
  }

  public getBillingAccountById(id: number): Observable<BillingAccount> {
    return this.getBillingAccounts(false)
      .pipe(
        take(1),
        map((billingAccounts) => billingAccounts.find((account) => account.id === id)!)
      );
  }

  public reloadBillingAccountOfSelectedTeam(): Observable<BillingAccount[]> {
    this.creditsBundleService.reloadBillingCreditsBundleOfUser();
    return this.getBillingAccounts(true);
  }

  public getBillingAccounts(forceReload = false): Observable<BillingAccount[]> {
    if (!forceReload && this.hasLoadedAccountsInitially) {
      return this.billingAccounts;
    }

    return this.loadBillingAccounts();
  }

  public createBillingAccount(teamId: number, billingAccountInput: CreateBillingAccount): Observable<BillingAccount> {
    return this.apollo.mutate<createBillingAccount, createBillingAccountVariables>({
      mutation: CREATE_BILLING_ACCOUNT_MUTATION,
      variables: { teamId, value: billingAccountInput }
    })
      .pipe(
        map((response) => response.data?.createBillingAccount!),
        tap(() => this.reloadBillingAccounts())
      );
  }

  public updateBillingAccount(teamId: number, billingAccountId: number, billingAccountInput: UpdateBillingAccount): Observable<BillingAccount> {
    return this.apollo.mutate<updateBillingAccount, updateBillingAccountVariables>({
      mutation: UPDATE_BILLING_ACCOUNT_MUTATION,
      variables: { teamId, billingAccountId, value: billingAccountInput }
    })
      .pipe(
        map((response) => response.data?.updateBillingAccount!),
        tap(() => this.reloadBillingAccounts())
      );
  }

  public deleteBillingAccount(teamId: number, billingAccountId: number): Observable<BillingAccount> {
    return this.apollo.mutate<deleteBillingAccount, deleteBillingAccountVariables>({
      mutation: DELETE_BILLING_ACCOUNT_MUTATION,
      variables: { teamId, billingAccountId }
    })
      .pipe(
        map((response) => response.data?.deleteBillingAccount!),
        tap(() => this.reloadBillingAccounts())
      );
  }

  public createUserCustomerPortalSession(): Observable<BillingAccountCustomerPortalSession> {
    return this.apollo.mutate<createUserCustomerPortalSession>({
      mutation: CREATE_USER_CUSTOMER_PORTAL_SESSION_MUTATION,
    })
      .pipe(
        map((response) => response.data?.createUserCustomerPortalSession!)
      );
  }

  public createBillingAccountCustomerSession(teamId: number, billingAccountId: number): Observable<BillingAccountCustomerPortalSession> {
    return this.apollo.mutate<createBillingAccountCustomerPortalSession, createBillingAccountCustomerPortalSessionVariables>({
      mutation: CREATE_BILLING_ACCOUNT_CUSTOMER_PORTAL_SESSION_MUTATION,
      variables: { teamId, billingAccountId }
    })
      .pipe(
        map((response) => response.data?.createBillingAccountCustomerPortalSession!)
      );
  }

  public createCheckoutSessionForPeopleSearchUsages(teamId: number, billingAccountId: number, productType: BillingProductPackageType): Observable<BillingAccountCheckoutSession> {
    const baseUrl = environment.domain + `/billing`;
    let successUrl = `${baseUrl}/${billingAccountId}/details?paymentCompletedSuccessfully=true`;
    let cancelUrl = `${baseUrl}/${billingAccountId}/details?errorOccurredOnPayment=true`;

    return this.apollo.mutate<createBillingAccountCheckoutSession, createBillingAccountCheckoutSessionVariables>({
      mutation: CREATE_BILLING_ACCOUNT_CHECKOUT_SESSION_MUTATION,
      variables: { teamId, billingAccountId, productPackageType: productType, successUrl, cancelUrl }
    })
      .pipe(
        map((response) => response.data?.createBillingAccountCheckoutSession!)
      );
  }

  public createPersonalCheckoutSessionForPeopleSearchUsages(productType: BillingProductPackageType): Observable<BillingAccountCheckoutSession> {
    const baseUrl = environment.domain + '/billing/personal';

    const successUrl = `${baseUrl}/details?paymentCompletedSuccessfully=true`;
    const cancelUrl = `${baseUrl}/details?errorOccurredOnPayment=true`;

    return this.apollo.mutate<createPersonalBillingCheckoutSession, createPersonalBillingCheckoutSessionVariables>({
      mutation: CREATE_PERSONAL_BILLING_ACCOUNT_CHECKOUT_SESSION_MUTATION,
      variables: { productPackageType: productType, successUrl, cancelUrl }
    })
      .pipe(
        map((response) => response.data?.createPersonalBillingCheckoutSession!)
      );
  }

  public getUserBillingCreditUsages(startDate: Date, endDate: Date): Observable<BillingCreditUsage[]> {
    return this.apollo.query<billingCreditsUsagesForUser, billingCreditsUsagesForUserVariables>({
    query: GET_USER_BILLING_CREDITS_USAGES_QUERY,
      variables: { startDate, endDate },
      fetchPolicy: 'network-only'
    })
      .pipe(
        map((response) => response.data?.billingCreditsUsagesForUser!)
      );
  }

  public getBillingCreditUsages(teamId: number, userId: number, startDate: Date, endDate: Date): Observable<BillingCreditUsage[]> {
    return this.apollo.query<billingCreditsUsages, billingCreditsUsagesVariables>({
      query: GET_BILLING_CREDITS_USAGES_QUERY,
      variables: { teamId, userId, startDate, endDate },
      fetchPolicy: 'network-only'
    })
      .pipe(
        map((response) => response.data?.billingCreditsUsages!)
      );
  }

  public getBillingCreditUsagesForBillingAccount(teamId: number, billingAccountId: number, startDate: Date, endDate: Date): Observable<BillingCreditUsage[]> {
    return this.apollo.query<billingCreditsUsagesForBillingAccount, billingCreditsUsagesForBillingAccountVariables>({
      query: GET_USER_BILLING_CREDITS_USAGES_FOR_BILLING_ACCOUNT_QUERY,
      variables: { teamId, billingAccountId, startDate, endDate },
      fetchPolicy: 'network-only'
    })
      .pipe(
        map((response) => response.data?.billingCreditsUsagesForBillingAccount!)
      );
  }

  public getBillingCreditUsageStatistics(teamId: number, startDate: Date, endDate: Date): Observable<BillingCreditUsageUserStatistics[]> {
    return this.apollo.query<billingCreditsUsageStatistics, billingCreditsUsageStatisticsVariables>({
      query: GET_BILLING_CREDITS_USAGE_STATISTICS_QUERY,
      variables: { teamId, startDate, endDate },
      fetchPolicy: 'network-only'
    })
      .pipe(
        map((response) => response.data?.billingCreditsUsageStatistics!)
      );
  }

  public getBillingCreditUsageStatisticsReport(teamId: number, startDate: Date, endDate: Date): Observable<BillingCreditUsageUserStatisticsReport> {
    return this.apollo.query<billingCreditsUsageStatisticsReport, billingCreditsUsageStatisticsReportVariables>({
      query: GET_BILLING_CREDITS_USAGE_STATISTICS_REPORT_QUERY,
      variables: { teamId, startDate, endDate },
      fetchPolicy: 'network-only'
    })
      .pipe(
        map((response) => response.data?.billingCreditsUsageStatisticsReport!)
      );
  }

  public updateBundleMetadata(bundleId: number, metadata: BillingCreditsBundleMetadata): Observable<BillingCreditsBundle> {
    return this.apollo.mutate<updateBundleMetadata, updateBundleMetadataVariables>({
      mutation: UPDATE_BUNDLE_METADATA,
      variables: {
        value: {
          bundleId,
          hasHiddenBundleHasExpiredWarning: metadata.hasHiddenBundleHasExpiredWarning,
          hasHiddenBundleIsEmptyWarning: metadata.hasHiddenBundleIsEmptyWarning,
          hasHiddenBundleIsExpiringSoonWarning: metadata.hasHiddenBundleIsExpiringSoonWarning,
        }
      },
      fetchPolicy: 'network-only'
    })
      .pipe(
        map((response) => response.data?.updateBundleMetadata!)
      );
  }

  private reloadBillingAccounts(): void {
    this.getBillingAccounts(true)
      .pipe(take(1))
      .subscribe();
  }

  private loadBillingAccounts(): Observable<BillingAccount[]> {
    this.hasLoadedAccountsInitially = true;
    return this.apollo.query<billingAccounts, billingAccountsVariables>({
      query: BILLING_ACCOUNTS_QUERY,
      fetchPolicy: 'network-only'
    })
      .pipe(
        map((response) => [...response.data!.billingAccounts]),
        tap((accounts) => this.billingAccounts.next(accounts)),
        catchError((error) => {
          console.error('Error getting billing accounts ' + error);
          return [];
        }),
        switchMap(() => this.billingAccounts)
      );
  }
}
