import { gql } from "apollo-angular";

export const BILLING_CREDITS_BUNDLE_METADATA_FRAGMENT = gql`
  fragment BillingCreditsBundleMetadata on BillingCreditsBundleMetadata {
    hasHiddenBundleIsEmptyWarning
    hasHiddenBundleIsExpiringSoonWarning
    hasHiddenBundleHasExpiredWarning
  }
`;

export const BILLING_CREDITS_BUNDLE_FRAGMENT = gql`
  fragment BillingCreditsBundle on BillingCreditsBundle {
    id
    billingAccountId
    userId
    creditsCount
    totalCreditsCount
    teamId
    expirationDate
    createdAt
    status
    metadata {
      hasHiddenBundleIsEmptyWarning
      hasHiddenBundleIsExpiringSoonWarning
      hasHiddenBundleHasExpiredWarning
    }
  }
`;

export const GET_ALL_BUNDLES_FOR_USER_QUERY = gql`
  query userCreditBundles {
    userCreditBundles {
      id
      userId
      creditsCount
      totalCreditsCount
      expirationDate
      createdAt
      status
      metadata {
        hasHiddenBundleIsEmptyWarning
        hasHiddenBundleIsExpiringSoonWarning
        hasHiddenBundleHasExpiredWarning
     }
    }
  }
`;

export const GET_ALL_BUNDLES_OF_USER = gql`
  query billingCreditBundles {
    billingCreditBundles {
      id
      billingAccountId
      userId
      creditsCount
      totalCreditsCount
      expirationDate
      createdAt
      status
      teamId
      metadata {
        hasHiddenBundleIsEmptyWarning
        hasHiddenBundleIsExpiringSoonWarning
        hasHiddenBundleHasExpiredWarning
     }
   }
  }
`;

export const ACTIVATE_USER_BUNDLE = gql`
  mutation activateUserBillingCreditsBundle($bundleId: Int!) {
    activateUserBillingCreditsBundle(bundleId: $bundleId) {
      status
    }
  }
`;

export const ACTIVATE_TEAM_BUNDLE = gql`
  mutation activateTeamBillingCreditsBundle($teamId: Int!, $billingAccountId: Int!, $bundleId: Int!) {
    activateTeamBillingCreditsBundle(teamId: $teamId, billingAccountId: $billingAccountId, bundleId: $bundleId) {
      status
    }
  }
`;

export const ACTIVATE_TEAM_BUNDLE_SITE_ADMIN = gql`
  mutation activateTeamBillingCreditsBundleSiteAdmin($teamId: Int!, $billingAccountId: Int!, $bundleId: Int!) {
    activateTeamBillingCreditsBundle(teamId: $teamId, billingAccountId: $billingAccountId, bundleId: $bundleId) {
      status
    }
  }
`;

export const UPDATE_BUNDLE_METADATA = gql`
  mutation updateBundleMetadata($value: UpdateBillingCreditsMetadata!) {
    updateBundleMetadata(value: $value) {
      id
      billingAccountId
      userId
      teamId
      creditsCount
      totalCreditsCount
      expirationDate
      createdAt
      status
      metadata {
        hasHiddenBundleIsEmptyWarning
        hasHiddenBundleIsExpiringSoonWarning
        hasHiddenBundleHasExpiredWarning
     }
    }
  }
`;
