import { Injectable, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, Subject, lastValueFrom } from 'rxjs';
import { map, switchMap, distinctUntilChanged, take, tap, takeUntil } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';
import { gql } from 'apollo-angular';

import { AuthService } from './auth.service';
import { getMe } from '../../../generated/getMe';
import { UserFull } from '../../../generated/UserFull';
import { Roles } from '../../../generated/globalTypes';
import { CreditBundleService } from '../../shared/services/credit-bundle.service';

export const USER_INFO_FRAGMENT = gql`
  fragment UserInfo on User {
    id
    firstName
    lastName
    name
    email
    picture
    isAccurintCreds
    accurintUsername
    lastLogin
    mfaEnabled
    userTeams {
      id
      role
      team {
        id
        name
      }
    }
  }
`;

export const USER_FULL_FRAGMENT = gql`
  fragment CaseRoleFull on CaseRole {
    id
    caseId
    role
  }

  fragment TeamInfo on Team {
    id
    name
    picture
    email
  }

  fragment UserFull on User {
    id
    sub
    email
    emailHash
    firstName
    lastName
    picture
    createdAt
    isSiteAdmin
    mfaEnabled
    isAccurintCreds
    accurintUsername
    kycStatus
    additionalRoles
    familyConnectionsLicensed
    creditsCount
    userTeams {
      id
      team {
        ...TeamInfo
      }
      role
    }
    caseRoles {
      ...CaseRoleFull
    }
  }
`;

const GET_ME_QUERY = gql`
  query getMe {
    me {
      ...UserFull
    }
  }

  ${USER_FULL_FRAGMENT}
`;

export const UPDATE_ACCURINT_CREDENTIALS = gql`
  mutation updateAccurintCredentials($teamId: Int!, $value: AccurintCredentialsInput!) {
    updateAccurintCredentials(teamId: $teamId, value: $value) {
      ...UserInfo
    }
  }
  ${USER_INFO_FRAGMENT}
`;

export const USERS_QUERY = gql`
  query getUsers($teamId: Int!) {
    users(teamId: $teamId) {
      ...UserInfo
    }
  }

  ${USER_INFO_FRAGMENT}
`;

export interface AdditionalRole {
  name: string;
  type: AdditionalRoleType;
}

export enum AdditionalRoleType {
  TEAM_CREATOR = 'TEAM_CREATOR',
}

@Injectable()
export class UserService implements OnDestroy {
  public static readonly TEAM_CREATOR = 'TEAM_CREATOR';
  public static readonly ADDITIONAL_ROLES: AdditionalRole[] = [{ name: 'Team Creator', type: AdditionalRoleType.TEAM_CREATOR }];
  public static readonly ADDITIONAL_ROLES_TYPES = [UserService.ADDITIONAL_ROLES.map((r) => r.type)];

  private _currentUser: UserFull | undefined;
  private _currentUser$ = new BehaviorSubject<UserFull | undefined>(undefined);

  public get currentUser(): UserFull | undefined {
    return this._currentUser;
  }

  public get currentUser$(): Observable<UserFull | undefined> {
    return this._currentUser$;
  }

  // ensures auth token is valid and profile has been retrieved
  // if auth token is invalid, try to refresh it
  // this function is for use with guards where you want to wait
  // for a refresh attempt before proceeding
  public async ensureUser(): Promise<UserFull> {
    // ensure token is valid and not expired - refresh if not
    await this.AuthService.token;

    // get current user profile
    return lastValueFrom(this.getUserProfile().pipe(take(1)));
  }

  public get isLoggedIn$(): Observable<boolean> {
    return this._currentUser$.pipe(
      map((u) => u !== undefined),
      distinctUntilChanged()
    );
  }

  public logout() {
    this.AuthService.logout();
  }

  private subscription = new Subject<void>();

  constructor(private apollo: Apollo,
    private AuthService: AuthService,
    private creditsBundleService: CreditBundleService) {
    this.AuthService.token$
      .pipe(
        takeUntil(this.subscription),
        switchMap((token) => {
          if (!token) {
            this.setCurrentUser(undefined);
            return this.apollo.getClient().resetStore(); // clear apollo cache
          }

          return this.getUserProfile();
        })
      )
      .subscribe(
        () => {},
        (err) => this.setCurrentUser(undefined)
      );
  }

  ngOnDestroy() {
    this.subscription.next();
    this.subscription.complete();
  }

  public reloadUserProfile() {
    return this.apollo.query<getMe>({ query: GET_ME_QUERY, fetchPolicy: 'network-only' }).pipe(
      map((res) => res.data.me),
      tap((res) => this.creditsBundleService.reloadBillingCreditsBundleOfUser()),
      tap((res) => this.setCurrentUser(res))
    );
  }

  public isAdmin(): Observable<boolean> {
    return this.getUserProfile().pipe(
      take(1),
      map((user) => user.isSiteAdmin)
    );
  }

  public isManager(teamId: number): boolean {
    const user = this._currentUser;
    const team = user?.userTeams.find((u) => u.team.id === teamId);
    return team?.role === Roles.MANAGER;
  }

  public getTeamRole(teamId: number) {
    const user = this._currentUser;
    const team = user?.userTeams.find((u) => u.team.id === teamId);
    return team?.role;
  }

  public hasTeamPermission(teamId: number) {
    return this.getTeamRole(teamId) !== Roles.NONE;
  }

  public isSiteAdmin(): boolean {
    return this.currentUser!.isSiteAdmin;
  }

  public canCreateTeam(): boolean {
    return this.canUserCreateTeam(this.currentUser);
  }

  public canCreateTeamObs(): Observable<boolean> {
    return this.getUserProfile().pipe(
      take(1),
      map((user) => this.canUserCreateTeam(user))
    );
  }

  private canUserCreateTeam(user: UserFull | undefined): boolean {
    return this.hasAdditionalRole(user, UserService.TEAM_CREATOR);
  }

  public hasAdditionalRole(user: UserFull | undefined, role: string): boolean {
    if (user?.isSiteAdmin) {
      return true;
    }

    const roles = user?.additionalRoles?.split(';');
    return roles?.includes(role) || false;
  }

  private getUserProfile(): Observable<UserFull> {
    return this.apollo.watchQuery<getMe>({ query: GET_ME_QUERY }).valueChanges.pipe(
      map((res) => res.data.me),
      tap((res) => this.setCurrentUser(res))
    );
  }

  private setCurrentUser(user: UserFull | undefined) {
    this._currentUser = user;
    this._currentUser$.next(user);
  }
}
