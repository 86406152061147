import { gql } from 'apollo-angular';

export const BILLING_ACCOUNT_FRAGMENT = gql`
  fragment BillingAccount on BillingAccount {
    id
    teamId
    name
    emailAddress
    description
    creditsCount
    isDefault
    createdAt
    updatedAt
  }
`;

export const BILLING_CREDIT_USAGE_FRAGMENT = gql`
  fragment BillingCreditUsage on BillingCreditUsage {
    createdAt
  }
`;

export const BILLING_CREDIT_USAGE_USER_STATISTICS_FRAGMENT = gql`
  fragment BillingCreditUsageUserStatistics on BillingCreditUsageUserStatistics {
    id
    picture
    firstName
    lastName
    count
  }
`;

export const BILLING_CREDIT_USAGE_USER_STATISTICS_REPORT_FRAGMENT = gql`
  fragment BillingCreditUsageUserStatisticsReport on BillingCreditUsageUserStatisticsReport {
    data
  }
`;

export const BILLING_ACCOUNT_CUSTOMER_PORTAL_SESSION_FRAGMENT = gql`
  fragment BillingAccountCustomerPortalSession on BillingAccountCustomerPortalSession {
    id
    url
  }
`;

export const BILLING_ACCOUNT_CHECKOUT_SESSION_FRAGMENT = gql`
  fragment BillingAccountCheckoutSession on BillingAccountCheckoutSession {
    url
  }
`;

export const BILLING_ACCOUNTS_QUERY = gql`
  query billingAccounts($teamId: Int) {
    billingAccounts(teamId: $teamId) {
      ...BillingAccount
    }
  }

  ${BILLING_ACCOUNT_FRAGMENT}
`;

export const CREATE_BILLING_ACCOUNT_MUTATION = gql`
  mutation createBillingAccount($teamId: Int!, $value: CreateBillingAccount!) {
    createBillingAccount(teamId: $teamId, value: $value) {
      ...BillingAccount
    }
  }

  ${BILLING_ACCOUNT_FRAGMENT}
`;

export const UPDATE_BILLING_ACCOUNT_MUTATION = gql`
  mutation updateBillingAccount($teamId: Int!, $billingAccountId: Int!, $value: UpdateBillingAccount!) {
    updateBillingAccount(teamId: $teamId, billingAccountId: $billingAccountId, value: $value) {
      ...BillingAccount
    }
  }

  ${BILLING_ACCOUNT_FRAGMENT}
`;

export const CREATE_USER_CUSTOMER_PORTAL_SESSION_MUTATION = gql`
  mutation createUserCustomerPortalSession {
    createUserCustomerPortalSession {
      id
      url
    }
  }
`;

export const CREATE_BILLING_ACCOUNT_CUSTOMER_PORTAL_SESSION_MUTATION = gql`
  mutation createBillingAccountCustomerPortalSession($teamId: Int!, $billingAccountId: Int!) {
    createBillingAccountCustomerPortalSession(teamId: $teamId, billingAccountId: $billingAccountId) {
      id
      url
    }
  }
`;

export const CREATE_BILLING_ACCOUNT_CHECKOUT_SESSION_MUTATION = gql`
  mutation createBillingAccountCheckoutSession($teamId: Int!, $billingAccountId: Int!, $productPackageType: BillingProductPackageType!, $successUrl: String!, $cancelUrl: String!) {
    createBillingAccountCheckoutSession(teamId: $teamId, billingAccountId: $billingAccountId, productPackageType: $productPackageType, successUrl: $successUrl, cancelUrl: $cancelUrl) {
      url
    }
  }
`;

export const CREATE_PERSONAL_BILLING_ACCOUNT_CHECKOUT_SESSION_MUTATION = gql`
  mutation createPersonalBillingCheckoutSession($productPackageType: BillingProductPackageType!, $successUrl: String!, $cancelUrl: String!) {
    createPersonalBillingCheckoutSession(productPackageType: $productPackageType, successUrl: $successUrl, cancelUrl: $cancelUrl) {
      url
    }
  }
`;

export const DELETE_BILLING_ACCOUNT_MUTATION = gql`
  mutation deleteBillingAccount($teamId: Int!, $billingAccountId: Int!) {
    deleteBillingAccount(teamId: $teamId, billingAccountId: $billingAccountId) {
      ...BillingAccount
    }
  }

  ${BILLING_ACCOUNT_FRAGMENT}
`;

export const GET_BILLING_CREDITS_USAGES_QUERY = gql`
  query billingCreditsUsages($teamId: Int!, $userId: Int!, $startDate: Date!, $endDate: Date!) {
    billingCreditsUsages(teamId: $teamId, userId: $userId, startDate: $startDate, endDate: $endDate) {
      ...BillingCreditUsage
    }
  }

  ${BILLING_CREDIT_USAGE_FRAGMENT}
`;

export const GET_USER_BILLING_CREDITS_USAGES_QUERY = gql`
  query billingCreditsUsagesForUser($startDate: Date!, $endDate: Date!) {
    billingCreditsUsagesForUser(startDate: $startDate, endDate: $endDate) {
      ...BillingCreditUsage
    }
  }

  ${BILLING_CREDIT_USAGE_FRAGMENT}
`;

export const GET_USER_BILLING_CREDITS_USAGES_FOR_BILLING_ACCOUNT_QUERY = gql`
  query billingCreditsUsagesForBillingAccount($teamId: Int!, $billingAccountId: Int!, $startDate: Date!, $endDate: Date!) {
    billingCreditsUsagesForBillingAccount(teamId: $teamId, billingAccountId: $billingAccountId, startDate: $startDate, endDate: $endDate) {
      ...BillingCreditUsage
    }
  }

  ${BILLING_CREDIT_USAGE_FRAGMENT}
`;

export const GET_BILLING_CREDITS_USAGE_STATISTICS_QUERY = gql`
  query billingCreditsUsageStatistics($teamId: Int!, $startDate: Date!, $endDate: Date!) {
    billingCreditsUsageStatistics(teamId: $teamId, startDate: $startDate, endDate: $endDate) {
      ...BillingCreditUsageUserStatistics
    }
  }

  ${BILLING_CREDIT_USAGE_USER_STATISTICS_FRAGMENT}
`;

export const GET_BILLING_CREDITS_USAGE_STATISTICS_REPORT_QUERY = gql`
  query billingCreditsUsageStatisticsReport($teamId: Int!, $startDate: Date!, $endDate: Date!) {
    billingCreditsUsageStatisticsReport(teamId: $teamId, startDate: $startDate, endDate: $endDate) {
      ...BillingCreditUsageUserStatisticsReport
    }
  }

  ${BILLING_CREDIT_USAGE_USER_STATISTICS_REPORT_FRAGMENT}
`;
